body,html{
	background-color: white !important;
}

:root {
    --dc-theme-dark-color: #ff7044;
}

.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }

  .btn-daytime{
	background-color: #85aad2  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-daytime:hover{
	color:black;
	opacity:100%;
  }
  
  .btn-nighttime{
	background-color: #5665b6  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-nighttime:hover{
	color:black;
	opacity:100%;
  }
  

  .bg-soon {
	color:black;
	background-color:#e9ca7a !important;
  }

  .btn-secondary {
	color: white !important; 
  }

  .xmint-btn{
	margin: 0 auto !important;
	margin-top:10px !important;
	margin-bottom:10px !important;
	font-size:10px !important;
}


.btn-before{
	border: 4px solid white !important;
}

.btn-after{
	border: 4px solid #ff7044 !important;
	opacity:0.8;
	pointer-events:none
}

.burn-button {
	background-color: #ff7044  !important;
	color: black !important;
	border: none  !important;
}

.burn-button:hover {
	background-color: #ff7044  !important;
	color: black !important;
	opacity:1;
}

.burn-button-reset {
	background-color: transparent !important;;
	color: white !important;
	border: 2px solid  #ff7044 !important;;
	opacity:0.9;
}

.burn-button-reset:hover {
	background-color: transparent !important;;
	color: white !important;
	border: 2px solid  #ff7044 !important;
	opacity:1;
}


.button-off {
	background-color: transparent;
	border:  none;
}

  button.xmint-btn span {
	display: none;
}
	button.xmint-btn:after {
	content: "Mint with Credit Card";
	color: white; 
	}


  .form-check-input:checked {
	background-color:#e9ca7a !important;
  }

  .form-check-input[type=checkbox]:indeterminate {
	background-color:#e9ca7a !important;
  }

  .form-check-input {
	background-color:#e9ca7a !important;
  }
  
  .btn-primary {
	color:black !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  .btn-primary:hover {
	color:white !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  
  .App-header {
	background-color: black;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  .form-check-input{
	cursor:pointer !important;
  }
  
  .mobile-filter{
	background-color:#20223b !important;
  }
  
  .sticky {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0;
	  z-index: 1 !important;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  .disabled {
	color: black !important;
	opacity: .5;
  }

  .disabled-header {
	color: black !important;
	opacity: .9;
	pointer-events: none;
  }

  .disabled-nav {
	color: white !important;
	opacity: .5;
  }

  .loading-tx {
	color: white !important;
  }

  .disabled-events{
	pointer-events: none !important;
  }

  .disabled-events-burn{
	opacity: .8;
	pointer-events: none !important;
  }

  .disabled-events-image {
	opacity: .5;
	pointer-events: none !important;
  }

  .remove {
	display:none !important
  }
  
  .mint-page-day {
	  overflow:hidden !important;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  text-align: center;
	  min-height: 91vh;
	background: url(bg.png) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  }

  .mint-page-mutation {
	overflow:hidden !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 91vh;
  background: url(bg.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mint-page-bonds {
	overflow:hidden !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 91vh;
	overflow: hidden !important;
	background: url(bg.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mint-page-war {
	min-height: 88vh;
	background: url(bg.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.shopbg {
	overflow:hidden !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 89vh;
	background: url(shopbg.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.aboutbg {
	min-height: 83vh;
	overflow:hidden !important;
	background: url(bgabout.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media only screen and (min-width: 600px) {
	/* .mobile{
		display: none !important;
	}

	.desktop {
		display: null !important;
	} */
}


@media only screen and (max-width: 600px) {

	/* .desktop{
		display: none !important;
	}

	.mobile {
		display: null !important;
	} */

	.mint-page-war {
		min-height: 88vh;
		background: url(bg.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}
	
	.shopbg {
		min-height: 88vh;
		background: url(shopbg.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}
	.aboutbg {
		min-height: 85vh;
		overflow:hidden !important;
		background: url(bgabout.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}
	.mint-page-bonds {
		overflow:hidden !important;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 89vh;
		background: url(bg.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}
  }


  .mint-page-night {
	overflow:hidden !important;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  text-align: center;
	  min-height: 91vh;
	  background: url(bg.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
  }
  
	.mint-module {
		background: rgba(23, 23, 28, 0.5);
		border: 4px solid black;
		/* min-width: 500px; */
	}

	.form-select{
		color:white !important;
		opacity: 1 !important;
	}

	.form-select::placeholder {
		font-weight: bold !important;
		opacity: 1 !important;
		color: white !important;
	}
	
	
  
  .mint-image {
	justify-content: center;
	  align-items: center;
	height:125px !important;
	width:125px !important;
	padding:10px;
	margin-top:20px;
	background: black;
	border-radius: 50%;
  }
  
  
	.mint-text-small {
	font-weight: 800;
	font-size: 24px;
	text-decoration:none;
	color: #FFFFFF;
	}

	.mint-text-extra-small {
		font-weight: 400;
		font-size: 12px;
		text-decoration:none;
		color: #FFFFFF;
		}
  
  .mint-text-large {
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	text-decoration:none;
  color: #FFFFFF;
  }
  
  .btn-war:hover{
	background-color: black !important;
  }
  
  .btn-day:hover{
	background-color: lightgreen !important;
  }
  
  .input-section {
	margin: 0 auto;
	padding:18px;
  }

  .none {
	display:  none !important;
  }

  .connect-section {
	margin: 0 auto;
  }

  .connect-section {
	text-align: center;
  }
  
  /* If the text has multiple lines, add the following: */
  .connect-section div {
	line-height: 1.5;
	display: inline-block;
	vertical-align: middle;
  }

  .text-loading {
	clip-path: inset(0 1.1ch 0 0);
	animation: text-load-animation 1s steps(4) infinite;
}
@keyframes text-load-animation {
	to {
		clip-path: inset(0 -.5ch 0 0)
}
}
.image-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
  }

  .image-wrapper-new {
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center;     /* Center vertically */
	height: 100vh;           /* Make the container take the full viewport height */
  }

  .image-wrapper-new img {
	animation: flash 1s infinite; /* Apply the animation, repeat indefinitely */
  }
  


  @keyframes flash {
	0%, 100% { opacity: 1; }  /* Fully visible at the start and end */
	50% { opacity: 0; }       /* Completely transparent in the middle */
  }
  
  
  @media (max-width: 767px) {
	.image-wrapper {
	  height: auto;
	}
  }
  
  .image-container {
	position: relative;
	height: 100%;
	width: calc(33.33% - 10px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	box-sizing: border-box;
  }
  
  .image-container {
	display: block;
	height: auto;
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	border: 2px dashed white;
  }
  
  .overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
  }
  
  .overlay img {
	display: block;
	object-fit: cover;
  }
  
  @media (max-width: 767px) {
	.image-container {
	  width: 100%;
	  margin-bottom: 10px;
	}
  }